@import '../_colours';

body .sidebar {
	min-height: 100vh;
}

.navbar-brand {
	margin-top: 9px;
	margin-left: 8px;
}

#sidebarToggle {
	position: absolute;
	width: 50px;
	top: 21px;
	right: 0;
}

.navbar-nav .nav-item .nav-link .badge {
	margin-left: -0.40rem;
}

.sidebar {
	border-right: 3px double #eee;
}

.accountButton {
	position: absolute;
	bottom: 25px;
	width: 100%;

	& > .btn-group > .btn {
		background-color: #ddd;
		border-radius: 20px;
		width: 80px;
		height: 40px;
	}
}
