@import './_colours';

@import 'node_modules/startbootstrap-sb-admin/scss/sb-admin';
@import 'node_modules/react-dual-listbox/lib/react-dual-listbox';

@-webkit-keyframes autofill {
	to {
		color: #000;
		background-color: #fff;
	}
}

input:-webkit-autofill {
	-webkit-animation-name: autofill;
	-webkit-animation-fill-mode: both;
}

.pointered {
	cursor: pointer;
}

.btn-earthi-vivid-green {
	color: #fff;
	background-color: $earthi-vivid-green;
	border-color: $earthi-vivid-green;

	&:hover {
		color: #fff;
	}
}

.modal {
	background-color: rgba(0, 0, 0, 0.3);
}
.modal-backdrop {
	position: relative;
}

.padded-span {
	padding: .38rem !important;
}
.modal-body dd {
	margin-left: 10px;
}

.earthi-logo {
	top: 8px;
	left: 14px;
	height: 42px;
}
.card-logo-header {
	padding: 0.25rem 0 0;
}
.card-logo {
	vertical-align: middle;
	margin-right: 5px;

	div {
		padding: 0;
		margin: 0;

		svg {
			height: 90px;
		}
	}
}
.menu-logo {
	vertical-align: middle;
	margin-right: 5px;

	div {
		padding: 0;
		margin: 0;

		svg {
			height: 45px;
		}
	}
}

#mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item > .nav-link.disabled {
	pointer-events: none;
	cursor: default;
	color: #51575d;
}

.max-w {
	max-width: 1100px;
}

.popover {
	max-width: 700px;
}
.popover-body {
	padding: 0;
	margin: 3px;
}

.profilePic {
	width: 135px;
}

.container-width {
	max-width: 1100px;
	margin-left: 0;
	min-width: 870px;
}

#sidenavToggler {
	background: none;
	border: none;
	width: 250px;
}

#wrapper #content-wrapper {
	padding-bottom: 40px;
}

.toast {
	position: absolute;
	top: 50px;
	right: 50px;

	&.show {
		z-index: 100;
	}
	&.hide {
		z-index: 0;
	}
}

// Bootstrap 3/4 fudge for react-jsonschema-form
.help-block {
	color: #6c757d !important;
	display: block;
	margin-top: 0.25rem;
	font-size: 80%;
	font-weight: 400;
}
